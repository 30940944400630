import UserStore from "./userStore";
import ProfessionalStore from "./professionalStore";
import ClientStore from "./clientStore";
import MeasurementProfilesStore from "./MeasurementProfilesStore";
import AppointmentStore from "./appointmentStore";
import AnamnesisStore from "./anamesisStore";
import DeviceManagementStore from "./deviceManagementStore";
import BluetoothStore from "./bluetoothStore";

export function resetStores() {
    for (const store in stores) {
        stores[store].reset();
    }
}

/**
 * Function that creates an interceptor for every method in the stores.
 * This is useful for the offline mode feature, and we won't need to change the store methods that have backend calls.
 * 
 * SYNC TURNED ON [ONLINE MODE]
 * 1 - The online method will be called normally.
 * 2 - The corresponding offline method will be called afterwards, to ensure persistence.
 * 
 * SYNC TURNED OFF [OFFLINE MODE]
 * 1 - The online method won't be called, as it's intercepted by the proxy.
 * 2 - The offline method will be called directly, to ensure persistence.
 */
function createMethodInterceptor(store, storeName) {
    return new Proxy(store, {
        get(target, property, receiver) {
            const originalValue = Reflect.get(target, property, receiver);

            // if the property is not a function, or if it's a non-configurable property, return the actual value and don't intercept
            const descriptor = Object.getOwnPropertyDescriptor(target, property);
            if (
                typeof originalValue !== "function" || 
                (descriptor && !descriptor.configurable) 
            ) {
                return originalValue; 
            }

            // if the code gets to this point, it means the property is a function and is configurable, so we'll intercept it
            return function (...args) {
                // console.log(`[CALL] Method '${String(property)}' on '${storeName}' called with args:`, args);

                // TODO: implement the logic to call the offline method here

                const result = originalValue.apply(target, args);

                // console.log(`[RESULT] Method '${String(property)}' returned:`, result);

                return result;
            };
        }
    });
}

function createProxiedStores() {
    const rawStores = {
        userStore: new UserStore(),
        professionalStore: new ProfessionalStore(),
        clientStore: new ClientStore(),
        measurementProfilesStore: new MeasurementProfilesStore(),
        appointmentStore: new AppointmentStore(),
        anamnesisStore: new AnamnesisStore(),
        deviceManagementStore: new DeviceManagementStore(),
        bluetoothStore: new BluetoothStore(),
    };

    const proxiedStores = {};

    for (const [storeName, store] of Object.entries(rawStores)) {
        proxiedStores[storeName] = createMethodInterceptor(store, storeName);
    }

    return proxiedStores;
}

export const stores = createProxiedStores();
