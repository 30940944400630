import Step from '../Step';

class SkinFoldsStep extends Step {
    equationId = null;

    secondaryEquations = [];

    skinFolds = [];

    alternateModeEnabled = true;

    trials = {}

    constructor(name, preset) {
        super(name, preset);
    }

    initFromData(data) {
        this.skinFolds = data.skinFolds || data.skin_folds;
        this.equationId = data.equationId || data.equation_id;
        this.secondaryEquations = data.secondaryEquations || data.secondary_equations;
        this.alternateModeEnabled = data.alternateModeEnabled || data.alternate_mode_enabled;
    }

    toJSON() {
        return {
            name: this.name,
            preset: this.preset,
            equation_id: this.equationId,
            secondary_equations: this.secondaryEquations,
            skin_folds: this.skinFolds,
            alternate_mode_enabled: this.alternateModeEnabled,
        };
    }
}

export default SkinFoldsStep;
