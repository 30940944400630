import Step from '../Step';

class StrengthStep extends Step {
    trials = {}

    constructor(name, preset) {
        super(name, preset);
    }
}

export default StrengthStep;
