import { action, makeObservable, observable, runInAction } from 'mobx';
import Profile from './models/Profile';
import agent from '../agent';
import { ANTHROPOMETRY_FIELDS } from '../constants';

/**
 * Store responsible for managing measurement profiles.
 * 
 * The process for editing and creating profiles is structured as follows:
 * 1. When a profile is selected or the user clicks "Add Profile," a copy of the Profile object is made.
 * 2. All operations are performed on this deep copy without modifying the original list.
 * 3. Once the user saves the profile, the copy is inserted into the list of profiles.
 */
class MeasurementProfilesStore {
    profiles = [];
    isLoading = false;
    currentProfile = null;
    currentStep = null;
    isAddingStep = false;

    constructor() {
        makeObservable(this, {
            profiles: observable,
            isLoading: observable,
            currentProfile: observable,
            currentStep: observable,
            isAddingStep: observable,
            loadProfiles: action,
            saveProfile: action,
            removeProfile: action,
            setIsAddingStep: action,
            setCurrentStep: action,
            setCurrentProfile: action,
            setCurrentProfileName: action,
            setCurrentStep: action,
            reset: action
        });
    }

    setIsAddingStep(isAddingStep) {
        runInAction(() => {
            this.isAddingStep = isAddingStep;
        });
    }
    setCurrentStep(step) {
        runInAction(() => {
            this.currentStep = step;
        });
    }
    setCurrentProfile(profile) {
        runInAction(() => {
            this.currentProfile = profile;
        });
    }
    setCurrentProfileName(name) {
        if (this.currentProfile) {
            runInAction(() => {
                this.currentProfile.name = name;
            });
        }
    }

    async loadProfiles() {
        this.isLoading = true;
        try {
            const body = {
                deleted: [],
                last_sync: "",
                updated: []
            };
            const response = await agent.MeasurementProfiles.list(body);
            runInAction(() => {
                this.profiles = response.updated
                    .map(profile => new Profile(
                        this,
                        profile.id,
                        profile.name,
                        profile.steps || [],
                        profile.is_removable ?? true,
                        profile.is_editable ?? true,
                        profile.sequence
                    ))
                    .sort((a, b) => a.sequence - b.sequence);
                console.log('Profiles loaded:', this.profiles);
            });

        } catch (error) {
            console.error("Error loading profiles ", error);
        } finally {
            this.isLoading = false;
        }
    }


    async removeStepFromProfile(profileId, stepName) {
        let profile = this.profiles.find(prof => prof.id === profileId);

        if (!profile && this.currentProfile && this.currentProfile.id === profileId) {
            profile = this.currentProfile;
        }

        if (profile) {
            const stepIndex = profile.steps.findIndex(step => step.name === stepName);

            if (stepIndex > -1) {
                try {
                    runInAction(() => {
                        profile.steps.splice(stepIndex, 1);
                        this.currentProfile = profile;
                    }
                    );
                    await this.saveProfile();
                } catch (error) {
                    console.error('Error removing step:', error);
                }
            }
        } else {
            console.error('Profile not found', profileId);
        }
    }

    async saveProfile() {
        let index = this.profiles.findIndex(prof => prof.id === this.currentProfile.id);

        let profileSteps = this.currentProfile.steps.map(step => ({
            name: step.name,
            preset: step.preset ?? null,
            sarcopenia_preset_strength: step.sarcopenia_preset_strength ?? [],
            sarcopenia_preset_quality: step.sarcopenia_preset_quality ?? [],
            sarcopenia_preset_severity: step.sarcopenia_preset_severity ?? [],
            additional_lipowise_measurements: step.additional_lipowise_measurements ?? [],
            additional_anthro_girths_measurements: step.additional_anthro_girths_measurements ?? [],
            additional_survey_questions: step.additional_survey_questions ?? [],
            equation_id: step.equationId ?? null,
            secondary_equations: step.secondaryEquations ?? [],
            skin_folds: step.skinFolds ?? [],
            alternate_mode_enabled: step.alternateModeEnabled ?? false,
            survey_options: step.surveyOptions ?? [],
            anthropometry_fields: step.anthropometryFields? step.anthropometryFields?.map(field => typeof field === 'string' ? field : ANTHROPOMETRY_FIELDS[step?.name][field]?.backendId) : [],
            test_type: step.test_type ?? null,
            interval: step.interval ?? null,
            parent_step: null,
            evaluation_type: step.evaluation_type ?? null,
            connect_to_gripwise: step.connectToGripwise ?? false,
            basic_measurements: step.basicMeasurements ?? [],
        }));

        let profileData = {
            id: this.currentProfile.id,
            name: this.currentProfile.name,
            steps: profileSteps,
            is_removable: this.currentProfile.isRemovable,
            is_editable: this.currentProfile.isEditable,
            parent_evaluation_profile: this.currentProfile.parent_evaluation_profile || null,
            association_date: this.currentProfile.association_date || new Date().toISOString(),
            created_at: this.currentProfile.created_at || new Date().toISOString(),
            updated_at: new Date().toISOString(),
            sequence: this.currentProfile.sequence
        };

        let syncData = {
            deleted: [],
            last_sync: new Date().toISOString(),
            updated: [profileData]
        };


        try {
            if (index === -1) {
                const maxSequence = Math.max(...this.profiles.map(prof => prof.sequence || 0), 0);
                const newSequence = maxSequence + 1;
                syncData.updated[0].sequence = newSequence;

                const createdProfile = await agent.MeasurementProfiles.add(syncData);
                this.profiles.push(new Profile(this, createdProfile.id, createdProfile.name, createdProfile.steps, createdProfile.is_removable, createdProfile.is_editable, createdProfile.sequence));
            } else {
                await agent.MeasurementProfiles.update(syncData);
                runInAction(() => {
                    this.profiles[index] = this.currentProfile;
                }
                );

            }
        } catch (error) {
            console.error('Error saving profile:', error);
        }
    }


    async changeProfileOrder(profileId, direction) {
        this.isLoading = true;

        // Armazena a ordem atual antes de realizar qualquer alteração
        const previousProfiles = [...this.profiles];
        let profiles = [...this.profiles];

        // Corrigir os valores de sequência 
        profiles = [
            ...profiles.filter(prof => !prof.isRemovable || !prof.isEditable),
            ...profiles.filter(prof => prof.isRemovable && prof.isEditable)
        ]
        profiles = profiles.map((prof, index) => {
            prof.sequence = index;
            return prof;
        });

        const profileToMove = profiles.find(prof => prof.id === profileId);

        if (!profileToMove) {
            console.error('Profile not found:', profileId);
            return;
        }

        let newSequence = -1, oldSequence = profileToMove.sequence;

        switch (direction) {
            case 'up':
                newSequence = oldSequence - 1;
                break;
            case 'down':
                newSequence = oldSequence + 1;
                break;
            default:
                console.error('Invalid direction:', direction);
                return;
        }

        // Coleta todos os perfis com nova sequência para envio ao backend
        let updatedProfiles = [
            ...profiles.filter(prof => !prof.isRemovable || !prof.isEditable),
            ...profiles.filter(prof => prof.isRemovable && prof.isEditable)
        ]

        updatedProfiles = updatedProfiles.map((prof, index) => {
            if (prof.id === profileId) {
                prof.sequence = newSequence;
            }
            if (prof.sequence === newSequence && prof.id !== profileId) {
                prof.sequence = oldSequence;
            }
            return prof;
        })
        updatedProfiles = updatedProfiles.sort((a, b) => a.sequence - b.sequence);

        // Cria o objeto syncData
        let syncData = {
            deleted: [],
            last_sync: new Date().toISOString(),
            updated: updatedProfiles.map(profile => ({
                id: profile.id,
                name: profile.name,
                steps: profile.steps.map(step => ({
                    name: step.name,
                    preset: step.preset ?? null,
                    sarcopenia_preset_strength: step.sarcopenia_preset_strength ?? [],
                    sarcopenia_preset_quality: step.sarcopenia_preset_quality ?? [],
                    sarcopenia_preset_severity: step.sarcopenia_preset_severity ?? [],
                    additional_lipowise_measurements: step.additional_lipowise_measurements ?? [],
                    additional_anthro_girths_measurements: step.additional_anthro_girths_measurements ?? [],
                    additional_survey_questions: step.additional_survey_questions ?? [],
                    equation_id: step.equationId ?? null,
                    secondary_equations: step.secondaryEquations ?? [],
                    skin_folds: step.skin_folds ?? [],
                    alternate_mode_enabled: step.alternateModeEnabled ?? false,
                    survey_options: step.surveyOptions ?? [],
                    anthropometry_fields: step.anthropometryFields ?? [],
                    test_type: step.test_type ?? null,
                    interval: step.interval ?? null,
                    parent_step: null,
                    evaluation_type: step.evaluation_type ?? null,
                    connect_to_gripwise: step.connectToGripwise ?? false,
                    basic_measurements: step.basicMeasurements ?? [],
                })),
                is_removable: profile.isRemovable,
                is_editable: profile.isEditable,
                parent_evaluation_profile: profile.parent_evaluation_profile || null,
                association_date: profile.association_date || new Date().toISOString(),
                created_at: profile.created_at || new Date().toISOString(),
                updated_at: new Date().toISOString(),
                sequence: profile.sequence,
            }))
        };

        try {
            // Envia o syncData ao backend para salvar todos os perfis atualizados
            await agent.MeasurementProfiles.update(syncData);

            runInAction(() => {
                // Atualiza os perfis na store após a confirmação do backend
                this.profiles = updatedProfiles;

                this.isLoading = false;
            });
        } catch (error) {
            // Se houve um erro, reverte para a ordem anterior
            runInAction(() => {
                this.profiles = previousProfiles;
                console.error('Erro ao atualizar a ordem dos perfis:', error);
            });
        }
    }





    async removeProfile(profileId) {
        this.isLoading = true;

        let index = this.profiles.findIndex(prof => prof.id === profileId);
        if (index > -1) {
            try {
                const body = {
                    deleted: [profileId],
                    last_sync: "",
                    updated: []
                };
                await agent.MeasurementProfiles.delete(body);
                runInAction(() => {
                    this.profiles = this.profiles.filter(prof => prof.id !== profileId);
                    
                    // fix the sequence
                    this.profiles = this.profiles.map((prof, index) => {
                        prof.sequence = index;
                        return prof;
                    });

                    this.isLoading = false;
                }
                );

            } catch (error) {
                console.error('Error removing Profile:', error);
            }
        }
    }

    reset() {
        this.profiles = [];
        this.isLoading = false;
        this.currentProfile = null;
        this.currentStep = null;
        this.isAddingStep = false;
    }
}

export default MeasurementProfilesStore;
