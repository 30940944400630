import React from 'react';
import {
    Avatar,
    IconButton,
    Box,
    Typography,
    Divider,
    Button,
    MenuItem,
    Modal,
    Card,
    List,
    Stack,
    Alert,
    Link,
} from '@mui/material';
import {
    Email as EmailIcon,
    Phone as PhoneIcon,
    Description as DescriptionIcon,
    PrivacyTip as PrivacyTipIcon,
    Link as LinkIcon,
    ChevronRight as ChevronRightIcon,
} from '@mui/icons-material';
import MenuPopover from './MenuPopover.jsx';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { alpha } from '@mui/material/styles';
import { stores } from '../stores';
import DefaultAvatar from './DefaultAvatar.jsx';
import { translate } from '../translations/translate.js';
import personFill from '@iconify/icons-eva/person-fill';
import hospitalIcon from '@iconify/icons-fa-solid/hospital-alt';
import { observer } from 'mobx-react-lite';
import { Icon } from '@iconify/react';
import styled from '@emotion/styled';
import { LoadingButton } from '@mui/lab';
import LazyImage from './LazyImage.jsx';

const ModalStyle = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    display: 'flex',
    flexDirection: 'column',
}));

export const UserPopover = observer(() => {
    let MENU_OPTIONS = [
        {
            label: 'profile',
            icon: personFill,
            linkTo: `../user/${stores.userStore.currentUser?.id}`
        },
        {
            label: 'entity',
            icon: hospitalIcon,
            linkTo: `../entity/${stores.userStore.entityId}`
        }
    ];

    const navigate = useNavigate()

    const anchorRef = React.useRef(null);
    const [open, setOpen] = React.useState(false);
    const [entitySwitchModalOpen, setEntitySwitchModalOpen] = React.useState(false);
    const [selectedEntity, setSelectedEntity] = React.useState(null);
    const [toastMessage, setToastMessage] = React.useState(null);
    const [toastSeverity, setToastSeverity] = React.useState(null);
    const [aboutVisible, setAboutVisible] = React.useState(false);

    if (!stores.userStore.loggedIn) return null;

    const handleOpen = () => {
        setOpen(!open);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleSwitchEntity = () => {
        setEntitySwitchModalOpen(true);
    }

    const handleLogout = () => {
        stores.userStore.logout();
    }

    const showToast = (message, severity = 'info') => {
        setToastMessage(message);
        setToastSeverity(severity);

        setTimeout(() => {
            setToastMessage(null);
            setToastSeverity(null)
        }, 5000);
    }

    const user = stores.userStore.currentUser;

    return <div>
        <IconButton
            ref={anchorRef}
            onClick={handleOpen}
            sx={{
                padding: 0,
                width: 44,
                height: 44,
                ...(open && {
                    '&:before': {
                        zIndex: 1,
                        content: "''",
                        width: '100%',
                        height: '100%',
                        borderRadius: '50%',
                        position: 'absolute',
                        bgcolor: (theme) => alpha(theme.palette.grey[900], 0.3)
                    }
                })
            }}
            size="large">
            {user?.photoURL ?
                <Avatar src={user?.photoURL || ""} alt={user.name} />
                :
                <DefaultAvatar name={user?.name || ''} />
            }
        </IconButton>


        <MenuPopover
            open={open}
            onClose={handleClose}
            anchorEl={anchorRef.current}
            sx={{ width: 220 }}
        >
            <Box sx={{ my: 1.5, px: 2.5 }}>
                <Typography variant="subtitle1" noWrap>
                    {user.name}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                    {user.email}
                </Typography>
            </Box>

            <Divider sx={{ my: 1 }} />

            {MENU_OPTIONS.map((option) => (
                <MenuItem
                    key={option.label}
                    to={option.linkTo}
                    component={RouterLink}
                    onClick={handleClose}
                    sx={{ typography: 'body2', py: 1, px: 2.5 }}
                >
                    <Box
                        component={Icon}
                        icon={option.icon}
                        sx={{
                            mr: 2,
                            width: 24,
                            height: 24
                        }}
                    />

                    {translate(option.label)}
                </MenuItem>
            ))}

            <Stack direction='column' p={2} pt={1.5} spacing={1}>
                <Button fullWidth color='inherit' variant='text' onClick={() => { setAboutVisible(true) }}>
                    {translate('about')}
                </Button>
                <Button fullWidth color="inherit" variant="outlined" onClick={handleSwitchEntity}>
                    {translate('switch_entity')}
                </Button>
                <Button fullWidth color="inherit" variant="outlined" onClick={handleLogout}>
                    {translate('logout')}
                </Button>
            </Stack>

            <Modal
                open={entitySwitchModalOpen}
                onClose={() => {
                    setEntitySwitchModalOpen(false);
                    setSelectedEntity(null);
                }}
                slotProps={{
                    backdrop: {
                        style: {
                            background: 'none',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }}
            >
                <Card
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        position: 'absolute',
                        width: 500,
                        maxHeight: '80vh',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                    }}
                >
                    <ModalStyle>
                        <>
                            <Typography variant='h4' mb={2} align='center'>
                                {translate('switch_entity')}
                            </Typography>
                            <List
                                sx={{
                                    borderRadius: 1,
                                    padding: 0,
                                    overflow: 'auto',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 1,
                                    p: 0.5,
                                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.3) inset',
                                    maxHeight: 300
                                }}
                            >
                                {stores.userStore?.entities.map((entity, index) => (
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        key={'entity-' + index}
                                        sx={{
                                            width: '100%',
                                            fontSize: '1.2rem',
                                            py: 1,
                                            textTransform: 'none',
                                            backgroundColor: selectedEntity?.id == entity?.id ? 'primary.main' : 'white',
                                            color: selectedEntity?.id == entity?.id ? 'white' : 'primary.main',
                                            boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
                                            '&:hover': {
                                                color: 'white',
                                                backgroundColor: 'rgba(67, 143, 167, 0.7)'
                                            }
                                        }}
                                        aria-pressed={selectedEntity === entity}
                                        onClick={() => {
                                            if (entity?.id == selectedEntity?.id) setSelectedEntity(null)
                                            else setSelectedEntity(entity)
                                        }}
                                    >
                                        {entity.name}
                                    </Button>
                                ))}
                            </List>
                            <LoadingButton
                                loading={stores.userStore?.inProgress?.selectEntity}
                                variant='contained'
                                color='primary'
                                disabled={!selectedEntity}
                                sx={{
                                    alignSelf: 'center',
                                    margin: 0,
                                    marginTop: 3,
                                    fontSize: '1.2rem'
                                }}
                                onClick={() => {
                                    if (!selectedEntity || selectedEntity.id == stores.userStore.entityId) {
                                        setEntitySwitchModalOpen(false);
                                        setSelectedEntity(null);
                                        setOpen(false);
                                        return;
                                    }

                                    navigate('/login')

                                    stores.userStore.switchEntity(selectedEntity)
                                        .then(() => {
                                            // show success message
                                            showToast(translate('entity_switched_successfully'), 'success');
                                        })
                                        .catch((error) => {
                                            console.error(error);
                                            // show error message
                                            showToast(translate('entity_switch_error'), 'error');
                                        })
                                        .finally(() => {
                                            setEntitySwitchModalOpen(false);
                                            setSelectedEntity(null);
                                            setOpen(false);
                                        });
                                }}
                            >
                                {translate('switch_entity')}
                            </LoadingButton>
                        </>
                    </ModalStyle>
                </Card>
            </Modal>

            <Modal
                open={aboutVisible}
                onClose={() => {
                    setAboutVisible(false)
                }}
                slotProps={{
                    backdrop: {
                        style: {
                            background: 'none',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }}
            >
                <Card
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        position: 'absolute',
                        width: 500,
                        maxHeight: '80vh',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                    }}
                >
                    <ModalStyle>
                        <LazyImage src='/img/about_logo.png' alt='Wisify Logo' style={{ width: '20%', height: 'auto', objectFit: 'contain', borderRadius: '50%', alignSelf: 'center' }} />

                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5, mt: 3 }}>
                            <Button
                                component="a"
                                href="https://wisifytech.com"
                                target="_blank"
                                rel="noopener noreferrer"
                                variant="outlined"
                                sx={{
                                    justifyContent: 'space-between',
                                    py: 1.5,
                                    px: 2,
                                    borderColor: 'divider',
                                    color: 'text.primary',
                                    '&:hover': {
                                        borderColor: 'primary.main',
                                        backgroundColor: 'action.hover'
                                    }
                                }}
                            >
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <LinkIcon sx={{ mr: 1.5, color: 'primary.main' }} />
                                    {translate('website')}
                                </Box>
                                <ChevronRightIcon sx={{ color: 'text.secondary' }} />
                            </Button>
                            <Button
                                component="a"
                                href="mailto:support@wisifytech.com"
                                variant="outlined"
                                sx={{
                                    justifyContent: 'space-between',
                                    py: 1.5,
                                    px: 2,
                                    borderColor: 'divider',
                                    color: 'text.primary',
                                    '&:hover': {
                                        borderColor: 'primary.main',
                                        backgroundColor: 'action.hover'
                                    }
                                }}
                            >
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <EmailIcon sx={{ mr: 1.5, color: 'primary.main' }} />
                                    {translate('email')}
                                </Box>
                                <ChevronRightIcon sx={{ color: 'text.secondary' }} />
                            </Button>
                            <Button
                                component="a"
                                href="https://wa.me/+351962678857"
                                target="_blank"
                                rel="noopener noreferrer"
                                variant="outlined"
                                sx={{
                                    justifyContent: 'space-between',
                                    py: 1.5,
                                    px: 2,
                                    borderColor: 'divider',
                                    color: 'text.primary',
                                    '&:hover': {
                                        borderColor: 'primary.main',
                                        backgroundColor: 'action.hover'
                                    }
                                }}
                            >
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <PhoneIcon sx={{ mr: 1.5, color: 'primary.main' }} />
                                    {translate('label_phone')}
                                </Box>
                                <ChevronRightIcon sx={{ color: 'text.secondary' }} />
                            </Button>
                            <Divider sx={{ my: 1 }} />
                            <Button
                                component="a"
                                href="https://app.wisifytech.com/main/terms-conditions.php"
                                target="_blank"
                                rel="noopener noreferrer"
                                variant="outlined"
                                sx={{
                                    justifyContent: 'space-between',
                                    py: 1.5,
                                    px: 2,
                                    borderColor: 'divider',
                                    color: 'text.primary',
                                    '&:hover': {
                                        borderColor: 'primary.main',
                                        backgroundColor: 'action.hover'
                                    }
                                }}
                            >
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <DescriptionIcon sx={{ mr: 1.5, color: 'primary.main' }} />
                                    {translate('terms_and_conditions')}
                                </Box>
                                <ChevronRightIcon sx={{ color: 'text.secondary' }} />
                            </Button>
                            <Button
                                component="a"
                                href="https://app.wisifytech.com/main/privacy-policy.php"
                                target="_blank"
                                rel="noopener noreferrer"
                                variant="outlined"
                                sx={{
                                    justifyContent: 'space-between',
                                    py: 1.5,
                                    px: 2,
                                    borderColor: 'divider',
                                    color: 'text.primary',
                                    '&:hover': {
                                        borderColor: 'primary.main',
                                        backgroundColor: 'action.hover'
                                    }
                                }}
                            >
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <PrivacyTipIcon sx={{ mr: 1.5, color: 'primary.main' }} />
                                    {translate('privacy_policy')}
                                </Box>
                                <ChevronRightIcon sx={{ color: 'text.secondary' }} />
                            </Button>

                            <Divider sx={{ my: 1 }} />

                            <LazyImage
                                src='/img/prr.png'
                                alt='PRR Logo'
                                style={{
                                    height: 'auto',
                                    width: '100%',
                                    objectFit: 'contain',
                                    maxWidth: '100%'
                                }}
                            />

                            <Typography variant='body2' align='center' mt={2} color='text.secondary'>
                                &copy; Wisify Tech Solutions, all rights reserved
                            </Typography>
                        </Box>
                    </ModalStyle>
                </Card>
            </Modal>

        </MenuPopover>
        {
            toastMessage && (
                <Alert
                    severity={toastSeverity}
                    sx={{
                        position: 'fixed',
                        top: 20,
                        left: '50%',
                        transform: 'translate(-50%, 0)',
                        zIndex: 1000
                    }}
                >
                    {toastMessage}
                </Alert>
            )
        }
    </div >
});