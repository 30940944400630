import { action, makeObservable, observable, runInAction } from "mobx";
import agent from "../agent";
import { stores } from ".";

class AppointmentStore {
    appointments = [];
    selectedAppointment = null;
    totalCount = 0;
    inProgress = false;
    errors = undefined;

    constructor() {
        makeObservable(this, {
            appointments: observable,
            totalCount: observable,
            inProgress: observable,
            errors: observable,
            selectedAppointment: observable,
            loadAppointments: action,
            clear: action,
            reset: action,
        });
    }

    selectAppointment(appointment) {
        runInAction(() => {
            this.selectedAppointment = appointment;
        });
    }

    loadAppointments = async (page = 1, pageSize = 10, selectFirstAppointment = false) => {
        this.inProgress = true;
        this.errors = undefined;
        const clientBackendId = stores.clientStore.selectedClient?.backend_id;

        try {
            const response = await agent.Appointment.list(clientBackendId, page, pageSize);
            runInAction(() => {
                this.appointments = response.results;
                const appointment = this.appointments[0];
                const client = stores.clientStore.selectedClient;
                if (client && appointment && appointment.results) appointment.results.client = client;
                if (selectFirstAppointment) this.selectedAppointment = appointment;
                this.totalCount = response.count;
            });
        } catch (error) {
            runInAction(() => {
                this.errors = error;
                console.error("Error loading appointments:", error);
            });
        } finally {
            runInAction(() => {
                this.inProgress = false;
            });
        }
    };

    clear() {
        this.appointments = [];
        this.selectedAppointment = null;
        this.totalCount = 0;
    }

    reset() {
        this.clear();
        this.inProgress = false;
        this.errors = undefined;
    }
}

export default AppointmentStore;
