import Step from '../Step';

class HandConfigurationStep extends Step {
    constructor(name) {
        super(name);
    }

    initFromData(data) {
        
    };
}



export default HandConfigurationStep;
