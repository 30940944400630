import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { stores } from '../stores/index'
import LazyImage from './LazyImage.jsx';

const Logo = observer(() => {
    return (
        <Link to={stores.userStore.getRedirectPath()}>
            <LazyImage
                src="/img/wisify_dark_logo.png"
                alt='logo'
                style={{
                    height: 40,
                    padding: '0 50px',
                    objectFit: 'contain',
                }}
            />
        </Link>
    )
})

export default Logo;
