import { makeAutoObservable, action, observable, runInAction, transaction } from "mobx";
import axios from "axios";
import agent from "../agent";
import { API_ROOT } from "../agent";
import { resetStores, stores } from "./index";
import { useNavigate } from "react-router-dom";
import isElectron from "is-electron";

class UserStore {
    // FIXME: add the rest of the fields
    loggedIn = false;
    username = null;
    language = 'en';
    entityId = null;
    entityName = null;
    entityCountry = null;
    entities = []
    token = null;
    inProgress = {
        getEntities: false,
        resetPassword: false,
        login: false,
        getUserByUniqueId: false,
        register: false,
        selectEntity: false
    }
    error = null;
    userByUniqueId = {};
    usersByUniqueId = [];
    currentUser = {};
    isManager = false;
    rememberMe = false;

    constructor() {
        makeAutoObservable(this, {
            pullEntitiesByUsername: action,
            login: action,
            backendLogin: action,
            setLoggedIn: action,
            setUsername: action,
            setLanguage: action,
            setEntityId: action,
            setEntityName: action,
            setEntityCountry: action,
            setEntities: action,
            setToken: action,
            setError: action,
            reset: action,
            setCurrentUser: action,
            setIsManager: action,
            getRedirectPath: action,
            selectEntity: action,
            switchEntity: action,
            checkTokenAndLastEntity: action,
            logout: action,
            requestPasswordReset: action,
            pullUserByUniqueId: action,
            register: action,
            setRememberMe: action
        });
    }

    setLoggedIn(loggedIn) {
        this.loggedIn = loggedIn;
    }

    setUsername(username) {
        this.username = username;
    }

    setLanguage(language, updateDB) {
        this.language = language;
    }

    setEntityId(entityId) {
        this.entityId = entityId;
    }

    setEntityName(entityName) {
        this.entityName = entityName;
    }

    setEntityCountry(entityCountry) {
        this.entityCountry = entityCountry;
    }

    setEntities(entities) {
        this.entities = entities;
    }

    setToken(token) {
        this.token = token;
    }

    setError(error) {
        this.error = error;
    }

    setCurrentUser(currentUser) {
        this.currentUser = currentUser;
    }

    setIsManager(isManager) {
        this.isManager = isManager;
    }

    setRememberMe(rememberMe) {
        this.rememberMe = rememberMe;
    }

    getRedirectPath() {
        if (this.isManager) return '/professionals';
        else return '/clients';
    }

    reset() {
        this.loggedIn = false;
        this.username = null;
        this.language = 'en';
        this.entityId = null;
        this.entityName = null;
        this.entityCountry = null;
        this.entities = [];
        this.token = null;
        this.error = null;
        this.userByUniqueId = null;
        this.usersByUniqueId = []
        this.currentUser = {}
        this.isManager = false;
        this.inProgress = {
            getEntities: false,
            resetPassword: false,
            login: false,
            getUserByUniqueId: false,
            register: false,
            selectEntity: false
        };
        this.errors = undefined;
        this.rememberMe = false;
    }

    /**
     * Called when the user presses the login button.
     * After each login, we replace auth.realm with the user's credentials, so that they can be used to automatically login.
     * 1. Check if the user's credentials exist in auth.realm.
     * 2. If they do, automatically login.
     * 3. If they don't, check if we have connection to the backend.
     * 4. If we do, check if the credentials are valid, and if they are, login.
     * 5. If they aren't, display an error message.
     * @param {Array} values - An array containing the username, password and entity ID.
    */
    async login(values) {
        runInAction(() => {
            this.inProgress.login = true;
        });

        const username = values.username;

        return this.backendLogin(values)
            .then((backendResult) => {
                if (backendResult?.token) {
                    runInAction(() => {
                        this.setToken(backendResult.token);
                        this.setLoggedIn(true);
                    });

                    agent.Entity.byUsername(username).then((entities) => {
                        runInAction(() => {
                            this.setEntities(entities);
                            if (this.rememberMe) localStorage.setItem('token', backendResult.token);
                        });
                    });
                } else {
                    console.error('Invalid credentials');
                }
                return true;
            });
    }

    async pullEntitiesByUsername(username) {
        runInAction(() => {
            this.inProgress.getEntities = true;
        });

        try {
            const entities = await agent.Entity.byUsername(username);
            if (entities) {
                runInAction(() => {
                    this.setEntities(entities);
                });
                return entities;
            }
        } catch (error) {
            console.error('Error fetching entities:', error.message);
        } finally {
            runInAction(() => {
                this.inProgress.getEntities = false;
            });
        }

        return [];
    }

    async backendLogin(values) {
        try {
            const response = await agent.Auth.login(values);
            if (response?.token) {
                return response;
            }
            return false;
        } catch (error) {
            runInAction(() => {
                this.setError(error.response.body);
            });
            return null;
        } finally {
            runInAction(() => {
                this.inProgress.login = false;
            });
        }
    }

    async selectEntity(entity, redirect = false, switchEntitySuccess = false) {
        let redirectPath = this.getRedirectPath();

        runInAction(() => {
            this.inProgress.selectEntity = true;
        });

        try {
            if (!entity || !entity.id) {
                console.error('Invalid entity:', entity);
                return false;
            }

            let response = await agent.Auth.selectEntity({ entity_id: entity.id });
            if (response?.message === 'Entity connected') {
                runInAction(() => {
                    this.setEntityId(entity.id);
                    this.setEntityName(entity.name);
                    this.setLanguage(entity.language);
                });

                const userResponse = await agent.User.current(2);
                if (userResponse) {
                    runInAction(() => {
                        this.setUsername(userResponse.username);
                        this.setEntityCountry(userResponse.current_entity.country);
                        this.setCurrentUser(userResponse);
                        this.setIsManager(userResponse?.is_manager);
                    });

                    if (userResponse?.is_manager) redirectPath = '/professionals';

                    // Store the last selected entity
                    if (this.rememberMe) {
                        try {
                            localStorage.setItem('lastSelectedEntity', entity.id);
                        } catch (storageError) {
                            console.error('Error saving lastSelectedEntity to localStorage:', storageError);
                        }
                    }

                    // Initialize the realm database
                    if (!window.api) return;
                    await window.api.initDB(userResponse.username);
                }

                return true;
            }
            return false;
        } catch (error) {
            console.error('Error selecting entity:', error.message);
            return false;
        } finally {
            runInAction(() => {
                this.inProgress.selectEntity = false;
            });

            if (redirect) {
                if (isElectron) {
                    window.location.hash = `${redirectPath}?switchEntitySuccess=${switchEntitySuccess}`;
                } else {
                    window.location.href = `${redirectPath}?switchEntitySuccess=${switchEntitySuccess}`;
                }
            }
        }
    }

    /**
     * This method is called when a user wants to select another entity from their list without having to log out.
     * For this, we'll store the token and username in variables, reset the stores, and mimic a login and entity selection.
     * The rememberMe flag is also stored in a variable, so that we can store the token in localStorage if the user wants to.
     */
    async switchEntity(entity) {
        try {
            runInAction(() => {
                this.inProgress.selectEntity = true;
            });

            const { token, username, rememberMe } = this;

            if (!token || !username) return false;

            // Reset the stores
            resetStores();

            // Mimic a login and entity selection
            runInAction(() => {
                this.setToken(token);
                this.setLoggedIn(true);
            });

            const entities = await agent.Entity.byUsername(username);
            runInAction(() => {
                this.setEntities(entities);
                if (rememberMe) localStorage.setItem('token', token);
            });

            const success = await this.selectEntity(entity, true, true);

            return success;
        } catch (error) {
            console.error('Error during switchEntity:', error.message);
            return false;
        } finally {
            runInAction(() => {
                this.inProgress.selectEntity = false;
            });
        }
    }

    checkTokenAndLastEntity() {
        // check if there's a token in localStorage
        if (!localStorage.getItem('token')) return;

        // store the token in a variable and hit the entities endpoint
        let token = localStorage.getItem('token');
        this.setToken(token);
        agent.Entity.byUsername(this.username)
            .then(entities => {
                // response is valid, so we set the entities and the loggedIn flag
                if (entities.length > 0) {
                    this.setEntities(entities);
                    this.setLoggedIn(true);

                    // try to get the last selected entity from localStorage
                    let lastSelectedEntity = localStorage.getItem('lastSelectedEntity');
                    if (lastSelectedEntity) {
                        let entity = entities.find(entity => entity.id == lastSelectedEntity);

                        if (!entity) {
                            localStorage.removeItem('lastSelectedEntity');
                            return;
                        }

                        // if the entity is found, select it and we should be good to go
                        this.selectEntity(entity);
                    }

                }
            })
            .catch(error => {
                // if there's an error, probably the token is invalid, so we remove it from localStorage and force the user to login again
                console.error('Error fetching entities:', error.message);

                localStorage.removeItem('token');
                localStorage.removeItem('lastSelectedEntity');

                this.reset();
            });
    }

    async logout() {
        resetStores()
        localStorage.removeItem('token');
        localStorage.removeItem('lastSelectedEntity');
        return Promise.resolve();
    }

    resetPassword(username) {
        this.inProgress.resetPassword = true;
        return agent.Auth.passwordReset({ username: username })
            .catch(err => {
                console.error('Error during password reset:', err);
            })
            .finally(() => {
                this.inProgress.resetPassword = false;
            });
    }

    pullUserByUniqueId = params => {
        this.inProgress.getUserByUniqueId = true;
        return agent.User.byUniqueId(params)
            .then(
                action(response => {
                    // if the response is a list, we set the usersByUniqueId
                    if (Array.isArray(response)) {
                        this.usersByUniqueId = response;
                    }
                    // otherwise we set the userByUniqueId
                    else {
                        this.userByUniqueId = response;
                    }
                })
            )
            .catch(
                action(err => {
                    console.error(err);
                })
            )
            .finally(
                action(() => {
                    this.inProgress.getUserByUniqueId = false;
                })
            );
    };

    register = values => {
        this.inProgress.register = true;
        this.errors = undefined;

        const body = JSON.parse(JSON.stringify(values));

        removeEmpty(body.entity)
        if (body.entity?.country) body.entity.country = body.entity.country.code

        body.legal_representative_username = body.legal_representative?.username;

        // legal representative address
        if (body.legal_representative && objEmpty(body.legal_representative.address)) {
            delete body['legal_representative']['address']
        }

        // legal_representative_email when legal_representative_found is false
        if (body.legal_representative_found) {
            delete body['legal_representative']
        }
        else {
            body.legal_representative.unique_id_country = body.legal_representative.unique_id_country.code
            body.legal_representative_email = body.legal_representative?.email;
        }

        // operations manager address
        if (body.operations_manager && objEmpty(body.operations_manager.address)) {
            delete body['operations_manager']['address']
        }

        // operations_manager_username when operations_manager_found is true
        // operations_manager_email when operations_manager_found is false
        if (!body.operations_manager?.operations_manager_is_legal_representative) {
            body.operations_manager_username = body.operations_manager?.username;
            if (body.operations_manager_found) {
                delete body['operations_manager']
            }
            else {
                body.operations_manager_email = body.operations_manager?.email;
                body.operations_manager.unique_id_country = body.operations_manager.unique_id_country.code
            }

        }
        else {
            body.operations_manager_is_legal_representative = body.operations_manager.operations_manager_is_legal_representative
            if (body.operations_manager) delete body['operations_manager']
        }

        return agent.Auth.register(body)
            .catch(
                action(err => {
                    this.errors = err.response.body;
                    throw err;
                })
            )
            .finally(
                action(() => {
                    this.inProgress.register = false;
                })
            );
    };
}

function removeEmpty(obj) {
    for (const propName in obj) {
        if (obj[propName] === null || obj[propName] === '') {
            delete obj[propName];
        }
    }
    return obj;
}

function objEmpty(obj) {
    return !Object.values(obj).filter(e => typeof e !== 'undefined').length
}

export default UserStore;