import { stores } from "../stores";

// Helper function to access nested objects via a string path (e.g., 'basic_measurement_info.bodyMass.definition')
function getNestedTranslation(obj, key) {
    // Verifica se a chave é uma string válida
    if (typeof key !== 'string') {
        return undefined;
    }

    // Usa split para quebrar a chave e tenta acessar o objeto
    return key.split('.').reduce((acc, part) => {
        if (acc && acc[part] !== undefined) {
            return acc[part];
        } else {
            return undefined;
        }
    }, obj);
}


export function translate(key, options = {}) {
    let languageFile = require(`../translations/${stores.userStore.language}.js`);
    
    // Use the helper function to access the nested translation
    let result = getNestedTranslation(languageFile.default, key) || key;

    // Replace any placeholders in the translation
    Object.entries(options).forEach(([placeholder, value]) => {
        const placeholderRegex = new RegExp(`{{${placeholder}}}`, 'g');
        result = result.replace(placeholderRegex, value);
    });

    return result;
}