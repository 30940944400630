import Step from '../Step';

class PresetStep extends Step {
    sarcopenia_preset_strength = ["S-STRENGTH"];
    sarcopenia_preset_quality = ["S-SKIN"];
    sarcopenia_preset_severity = ["S-WALK"];
    additional_lipowise_measurements = [0, 6, 7];
    additional_anthro_girths_measurements = ["calf"];
    additional_survey_questions = [3];
    
    constructor(name, preset = "Preset Title") {
        super(name, preset);
    }

    initFromData(data) {
        this.sarcopenia_preset_strength = data.sarcopenia_preset_strength ?? data.sarcopeniaPresetStrength;
        this.sarcopenia_preset_quality = data.sarcopenia_preset_quality ?? data.sarcopeniaPresetQuality;
        this.sarcopenia_preset_severity = data.sarcopenia_preset_severity ?? data.sarcopeniaPresetSeverity;
        this.additional_lipowise_measurements = data.additional_lipowise_measurements ?? data.additionalLipowiseMeasurements;
        this.additional_anthro_girths_measurements = data.additional_anthro_girths_measurements ?? data.additionalAnthroGirthsMeasurements;
        this.additional_survey_questions = data.additional_survey_questions ?? data.additionalSurveyQuestions;        
    }

    toJSON() {
        return {
            name: this.name,
            preset: this.preset,
        };
    }
}

export default PresetStep;
