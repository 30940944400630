import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { Routes, Route, Navigate, HashRouter, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { routes } from './constants';
import { Box } from '@mui/material';
import ThemeConfig from './theme/index.jsx';
import { stores } from './stores/index.js';

const App = observer(() => {
    useEffect(() => {
        // verify if there's a token in localStorage and check if it's valid, and if not redirect to login
        // if the token is valid and the last selected entity is not set, redirect to the entities page
        // otherwise, login and select the last selected entity
        stores.userStore.checkTokenAndLastEntity();
    }, []);

    return (
        <ThemeConfig>
            <Box sx={{ width: '100%', height: '100%' }}>
                <HashRouter>
                    <Routes>
                        {routes.map((route, index) => (
                            route.requiresManagerPermission && stores.userStore.getRedirectPath() === '/clients' ?
                                <Navigate to='/clients' /> // navigate to the clients page (doesn't require manager permissions) if user is not a manager and the screen requires manager permissions
                                :
                                <Route
                                    key={index}
                                    path={route.path}
                                    element={<route.component routes={route.routes} />}
                                />
                        ))}
                        <Route path="*" element={<Navigate to='/' />} />
                    </Routes>
                </HashRouter>
            </Box>
        </ThemeConfig>
    );
});

const root = createRoot(document.getElementById('root'));
root.render(<App />);
