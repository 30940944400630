import { action, makeObservable, observable, runInAction } from 'mobx';
import agent from '../agent';
import { stores } from '.';

function removeEmpty(obj) {
    for (const propName in obj) {
        if (obj[propName] === null || obj[propName] === '') {
            delete obj[propName];
        }
    }
    return obj;
}

export const GROUP_PROFESSIONALS_ONLY = 1;
export const GROUP_PROFESSIONALS_AND_CLIENTS = 2;

class ProfessionalStore {
    inProgress = {
        register: false,
        getProfessional: false,
        getAll: false,
    };

    all = [];

    professionalInfo = null;

    // Default order of the foldable steps in the profile 
    //TODO: change when professiona update is implemented
    defaultFoldOrder = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

    groups = [];

    constructor() {
        makeObservable(this, {
            inProgress: observable,
            all: observable,
            professionalInfo: observable,
            defaultFoldOrder: observable,
            groups: observable,
            register: action,
            get: action,
            getAll: action,
            setProfessionalInfo: action,
            reset: action,
            removeFromEntity: action,
            setDefaultFoldOrder: action,
            getGroups: action,
            getGroup: action,
            saveGroup: action,
            addGroup: action,
            deleteGroup: action,
            removeFromGroup: action
        });
    }

    register = values => {
        this.inProgress.register = true;
        this.errors = undefined;

        let professional_id = values.professional_id;

        const body = {};
        body.person = JSON.parse(JSON.stringify(values));
        body.username = body.person.username;
        delete body.person?.professional_id
        body.professional_id = professional_id;

        removeEmpty(body.person);
        removeEmpty(body.person?.address);

        if (body.person.person_id) {
            body.person_id = body.person.person_id;
            if (!body.person.person_email) body.professional_email = body.person.email;
            body.person = undefined;
        } else body.person.unique_id_country = body.person.unique_id_country.code;

        return agent.Professional.add(body)
            .then(() => {
                agent.Professional.list().then(
                    action(professionals => {
                        this.all = professionals;
                    })
                )
            })
            .catch(
                action(err => {
                    this.errors = err?.response.body;
                    throw err;
                })
            )
            .finally(
                action(() => {
                    this.inProgress.register = false;
                })
            );
    };

    get = id => {

    };

    getAll = () => {
        this.inProgress.getAll = true;

        return agent.Professional.list().then(action(professionals => {
            this.all = professionals;
        }))
            .catch(action(err => { throw err; }))
            .finally(action(() => { this.inProgress.getAll = false; }));
    };

    setProfessionalInfo = professional => {
        this.professionalInfo = professional;
    }

    removeFromEntity = professional_id => {
        return agent.Professional.removeFromEntity({ professional_id })
            .then(() => {
                agent.Professional.list().then(
                    action(professionals => {
                        this.all = professionals;
                    })
                )
            })
            .catch(
                action(err => {
                    this.errors = err?.response.body;
                    throw err;
                })
            )
    }

    setDefaultFoldOrder = order => {
        this.defaultFoldOrder = order;
    };

    getGroups = () => {
        return agent.Professional.getGroups()
            .then(groups => {
                runInAction(() => {
                    let professionalsOnlyGroups = groups[0] || [];
                    let professionalsAndClientsGroups = groups[1] || [];

                    professionalsOnlyGroups.forEach(group => {
                        group.type = GROUP_PROFESSIONALS_ONLY;
                    });
                    professionalsAndClientsGroups.forEach(group => {
                        group.type = GROUP_PROFESSIONALS_AND_CLIENTS;
                    });

                    this.groups = professionalsOnlyGroups.concat(professionalsAndClientsGroups);
                });
            })
            .catch(err => {
                console.log('Error getting groups', err);
            });
    }

    getGroup = (id) => {
        return new Promise((resolve, reject) => {
            const group = this.groups.find(group => parseInt(group.id) === parseInt(id));
            if (!group) reject('Group not found');
            resolve(group);
        })
    }

    saveGroup = (group) => {
        try {
            const body = {
                "created": [],
                "updated": [
                    {
                        ...group
                    }
                ],
                "removed": [],
                "deleted": []
            }

            return agent.Professional.addGroup(body)
                .then(() => {
                    this.getGroups();
                })
                .catch(err => {
                    throw err;
                });
        }
        catch (error) {
            throw error;
        }
    }

    addGroup = (values) => {
        try {
            const body = {
                "created": [
                    {
                        "type": values.type,
                        "name": values.name,
                        "professional_members": [stores.userStore.currentUser.id],
                        "permissions": [
                            "view_all_patients",
                            "can_create_groups"
                        ]
                    }
                ],
                "updated": [],
                "removed": [],
                "deleted": []
            }

            return agent.Professional.addGroup(body)
                .then(() => {
                    this.getGroups();
                })
                .catch(err => {
                    throw err;
                });
        } catch (error) {
            throw error;
        }
    }

    deleteGroup = (group) => {
        try {
            const body = {
                "created": [],
                "updated": [],
                "removed": [],
                "deleted": [
                    {
                        "id": group.id,
                        "type": group.type
                    }
                ]
            }

            return agent.Professional.addGroup(body)
                .then(() => {
                    this.getGroups();
                })
                .catch(err => {
                    throw err;
                });
        } catch (error) {
            throw error;
        }
    }

    removeFromGroup = (group, userId, userType) => {
        try {
            const body = {
                "created": [],
                "updated": [],
                "removed": [
                    {
                        "id": group.id,
                        "type": group.type,
                        "name": group.name,
                        "professional_members": userType === 'professional' ? [userId] : [],
                        "patient_members": userType === 'client' ? [userId] : []
                    }
                ],
                "deleted": []
            }

            return agent.Professional.addGroup(body)
                .then(() => {
                    this.getGroups();
                })
                .catch(err => {
                    throw err;
                });
        } catch (error) {
            throw error;
        }
    }

    reset() {
        this.inProgress = {
            register: false,
            getProfessional: false,
            getAll: false,
        };
        this.all = [];
        this.professionalInfo = null;
        this.groups = [];
    }
}

export default ProfessionalStore;