import { action, makeObservable, observable, runInAction } from "mobx";
import agent from "../agent";
import { formatDateWithMicroseconds } from "../utils";

class ClientStore {
    all = [];
    selectedClient = null;
    bodyWeightEvolution = [];
    bmiEvolution = [];
    muscleMassEvolution = [];
    bodyFatEvolution = [];
    inProgress = {
        register: false,
        update: false,
        loadEvolution: false,
    };
    errors = undefined;

    constructor() {
        makeObservable(this, {
            all: observable,
            selectedClient: observable,
            bodyWeightEvolution: observable,
            inProgress: observable,
            errors: observable,
            register: action,
            update: action,
            getClient: action,
            getAll: action,
            setSelectedClient: action,
            loadBodyWeightEvolution: action,
            clearBodyWeightEvolution: action,
            reset: action,
        });
    }

    setSelectedClient = (client) => {
        this.selectedClient = client;
    };

    register = values => {
        this.inProgress.register = true;

        let person = JSON.parse(JSON.stringify(values));

        let clientData = {
            backend_id: person.backend_id || '',
            unique_id_number: person.unique_id_number || '',
            unique_id_country: person.unique_id_country?.code || '',
            association_date: new Date().toISOString(),
            name: person.name || '',
            email: person.email || null,
            phone_number: person.phone_number || '',
            birthdate: person.birthdate || '',
            gender: person.gender || '',
            ethnicity: '',
            address: {
                street: person.address?.street || '',
                postal_code: person.address?.postal_code || '',
                locality: person.address?.locality || '',
                city: person.address?.city || '',
                state_province_region: person.address?.state_province_region || '',
                country: person.address?.country || '',
            }
        };

        let body = {
            updated: [clientData],
            deleted: [],
            last_sync: new Date().toISOString()
        };

        return agent.Client.add(body)
            .then(() => {
                agent.Client.list().then(
                    action(clients => {
                        this.all = clients;
                    })
                );
            })
            .catch(
                action(err => {
                    console.error(err);
                    throw err;
                })
            )
            .finally(
                action(() => {
                    this.inProgress.register = false;
                })
            );
    };

    update = async (values) => {
        this.inProgress.update = true;
        try {
            let body = {
                updated: [],
                deleted: [],
                last_sync: new Date().toISOString()
            };

            let clientData = {
                backend_id: values.backend_id || '',
                updated_at: formatDateWithMicroseconds(),
                association_date: values.association_date || null,
                name: values.name || '',
                email: values.email || '',
                phone_number: values.phone_number || '',
                birthdate: values.birthdate || '',
                gender: values.gender || '',
                ethnicity: values.ethnicity || '',
                unique_id_country: values.unique_id_country || '',
                unique_id_number: values.unique_id_number || '',
                address: {
                    type: 1,
                    street: values.address?.street || '',
                    postal_code: values.address?.postal_code || '',
                    locality: values.address?.locality || '',
                    city: values.address?.city || '',
                    state_province_region: values.address?.state_province_region || '',
                    country: values.unique_id_country || '',
                }
            };

            body.updated.push(clientData);

            const response = await agent.Client.add(body);
            if (response.updated && response.updated.length > 0 && response.num_updated > 0) {
                const updatedClient = response.updated[response.updated.length - 1];
                const clientIndex = this.all.findIndex(client => client.backend_id === updatedClient.backend_id);
                this.all[clientIndex] = updatedClient;

                return { success: true, message: "Client updated successfully." };
            } else {
                return { success: false, message: "Error updating client." };
            }
        } catch (error) {
            console.error("Error updating client.", error);
            let errorMessage = "Error updating client.";
            if (error.response) {
                if (error.response.data && error.response.data.message) {
                    errorMessage = `Backend error: ${error.response.data.message}`;
                } else if (error.response.status) {
                    errorMessage = `Request failed with status code ${error.response.status}`;
                }
            } else if (error.message) {
                errorMessage = error.message;
            }
            return { success: false, message: errorMessage };
        } finally {
            this.inProgress.update = false;
        }
    };

    getClient = id => {
        return this.all.find(client => client.id === id);
    };

    getAll = () => {
        return agent.Client.list()
            .then(
                action(clients => {
                    this.all = clients;
                })
            )
            .catch(err => {
                console.error(err);
                throw err;
            });
    };

    loadBodyWeightEvolution = async () => {
        this.inProgress.loadEvolution = true;
        this.errors = undefined;

        try {
            const response = await agent.Client.getBodyWeightEvo(this.selectedClient?.backend_id);
            runInAction(() => {
                this.bodyWeightEvolution = response;
            });
        } catch (error) {
            runInAction(() => {
                this.errors = error;
                console.error("Error loading body weight evolution:", error);
            });
        } finally {
            runInAction(() => {
                this.inProgress.loadEvolution = false;
            });
        }
    };
    clearBodyWeightEvolution = () => {
        this.bodyWeightEvolution = [];
    };

    loadBMIEvolution = async () => {
        this.inProgress.loadEvolution = true;
        this.errors = undefined;

        try {
            const response = await agent.Client.getBMIEvolution(this.selectedClient?.backend_id);
            runInAction(() => {
                this.bmiEvolution = response;
            });
        } catch (error) {
            runInAction(() => {
                this.errors = error;
                console.error("Error loading BMI evolution:", error);
            });
        } finally {
            runInAction(() => {
                this.inProgress.loadEvolution = false;
            });
        }
    };
    clearBMIEvolution = () => {
        this.bmiEvolution = [];
    };

    loadMuscleMassEvolution = async () => {
        this.inProgress.loadEvolution = true;
        this.errors = undefined;

        try {
            const response = await agent.Client.getMuscleMassEvolution(this.selectedClient?.backend_id);
            runInAction(() => {
                this.muscleMassEvolution = response;
            });
        } catch (error) {
            runInAction(() => {
                this.errors = error;
                console.error("Error loading muscle mass evolution:", error);
            });
        } finally {
            runInAction(() => {
                this.inProgress.loadEvolution = false;
            });
        }
    };
    clearMuscleMassEvolution = () => {
        this.muscleMassEvolution = [];
    };

    loadBodyFatEvolution = async () => {
        this.inProgress.loadEvolution = true;
        this.errors = undefined;

        try {
            const response = await agent.Client.getBodyFatEvolution(this.selectedClient?.backend_id);
            runInAction(() => {
                this.bodyFatEvolution = response;
            });
        } catch (error) {
            runInAction(() => {
                this.errors = error;
                console.error("Error loading body fat evolution:", error);
            });
        } finally {
            runInAction(() => {
                this.inProgress.loadEvolution = false;
            });
        }
    };
    clearBodyFatEvolution = () => {
        this.bodyFatEvolution = [];
    };

    reset() {
        this.all = [];
        this.selectedClient = null;
        this.bodyWeightEvolution = [];
        this.bmiEvolution = [];
        this.muscleMassEvolution = [];
        this.bodyFatEvolution = [];
        this.inProgress = {
            register: false,
            update: false,
            loadEvolution: false,
        };
        this.errors = undefined;
    }
}

export default ClientStore;
