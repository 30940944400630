import { action, makeObservable, observable, runInAction } from "mobx";
import agent from "../agent";
import { stores } from "./";

class DeviceManagementStore {
    savedDevices = [];
    inProgress = false;

    constructor() {
        makeObservable(this, {
            savedDevices: observable,
            inProgress: observable,
            loadDevices: action,
            addDevice: action,
            removeDevice: action,
            reset: action,
            setDeviceField: action,
        });
    }

    async loadDevices() {
        runInAction(() => {
            this.inProgress = true;
        });

        try {
            const devices = await agent.Devices.list();

            if (window.api) {
                await window.api.delete('Device', '');

                for (const device of devices) {
                    let convertedDevice = {};
                    for (let field of Object.keys(device)) {
                        convertedDevice[field.replace(/_([a-z])/g, g => g[1].toUpperCase())] = device[field];
                    }
                    Object.assign(device, convertedDevice);

                    await window.api.create('Device', device);
                }
            }

            runInAction(() => {
                this.savedDevices = devices || [];
            });
        } catch (error) {
            console.error('Error loading devices from backend: ', error);
        } finally {
            runInAction(() => {
                this.inProgress = false;
            });
        }
    }

    async setDeviceField(deviceId, field, value) {
        runInAction(() => {
            this.inProgress = true;
        });

        try {
            const device = this.savedDevices.find(device => device.id === deviceId);
            if (!device) {
                console.error(`Device with id ${deviceId} not found.`);
                return;
            }

            const updatedDevice = { ...device, [field]: value };

            let convertedDevice = {};
            for (let field of Object.keys(updatedDevice)) {
                convertedDevice[field.replace(/([A-Z])/g, "_$1").toLowerCase()] = updatedDevice[field];
            }

            await agent.Devices.update(convertedDevice);

            if (window.api) {
                await window.api.update('Device', `id = "${deviceId}"`, { [field]: value });
            }

            await this.loadDevices();
        } catch (error) {
            console.error(`Error updating field ${field} for device with id ${deviceId}: `, error);
        } finally {
            runInAction(() => {
                this.inProgress = false;
            });
        }
    }

    async addDevice(device) {
        runInAction(() => {
            this.inProgress = true;
        });

        try {
            let convertedDevice = {};
            for (let field of Object.keys(device)) {
                convertedDevice[field.replace(/([A-Z])/g, "_$1").toLowerCase()] = device[field];
            }

            // don't add the device if it's already in the list, we're just gonna connect to it
            if (!this.savedDevices.find(d => d.mac_address === device.macAddress || d.macAddress === device.macAddress)) {
                await agent.Devices.add(convertedDevice);
    
                if (window.api) {
                    await window.api.create('Device', device);
                }                
            }

            await this.loadDevices();
        } catch (error) {
            console.error(`Error adding device with id ${device.id}: `, error);
        } finally {
            runInAction(() => {
                this.inProgress = false;
            });
        }
    }

    async removeDevice(deviceId) {
        runInAction(() => {
            this.inProgress = true;
        });

        try {
            await agent.Devices.add({ deleted: deviceId });

            if (window.api) {
                await window.api.delete('Device', `id = "${deviceId}"`);
            }

            await this.loadDevices();
        } catch (error) {
            console.error(`Error removing device with id ${deviceId}: `, error);
        } finally {
            runInAction(() => {
                this.inProgress = false;
            });
        }
    }

    reset() {
        runInAction(() => {
            this.savedDevices = [];
            this.inProgress = false;
        });
    }
}

export default DeviceManagementStore;