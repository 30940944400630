import Step from '../Step';

class CheckpointStep extends Step {
    evaluation_type = null;

    constructor(name, preset) {
        super(name, preset);
    }

    initFromData(data) {
        this.evaluation_type = data.evaluation_type;
    };
}

export default CheckpointStep;
