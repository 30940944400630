import superagentPromise from 'superagent-promise';
import _superagent, { get } from 'superagent';
import { stores } from './stores';
import { Navigate } from 'react-router-dom';
import React from 'react';
import isElectron from 'is-electron';

/**
 * This file contains the API agent, which is responsible for making requests to our backend.
 * It uses the superagent library to make requests, and superagent-promise to handle promises.
 * 
 * How it works:
 * - The requests object contains common request methods for different HTTP verbs.
 * - The other objects use these methods to define specific API requests, e.g., Auth, User, Entity, etc.
 * - The tokenPlugin function sets the authorization token in requests, if it is available.
 * - The handleErrors function is used to handle errors returned from API requests.
 * - The responseBody function extracts the response body from API responses.
 * - The API_ROOT constant defines the root URL for the API, which is used in all requests.
 * - The encode function is used to encode URI components.
 * 
 * How to create a new API request:
 * - If the HTTP verb is not already defined in the requests object, add it there.
 * - Create a new object in this file for the specific API request, e.g., Auth, User, Entity, etc, or add it to an existing object.
 * - Define a function in the object that uses the request method from the requests object.
 * - Export the object at the end of the file, if it is a new object.
 * - Import the object where you need to use the API request.
 * - Call the function in the object to make the API request.
 * 
 * Example:
 * - To get the scheduled appointments for a professional, you can add a new request to the Professional object:
 * - Define a function in the Professional object that uses the requests.get method:
 * - listAppointments: (id) => requests.get(`professionals/${encode(id)}/appointments/`
 * Note: This endpoint has to be implemented in the backend first, and the response should be handled accordingly.
 */

const encode = encodeURIComponent;

// Define the root URL for the API
export const API_ROOT = (isElectron() ? (await window.api.getEnvVar('BASE_URL')) : 'https://newsb.wisifytech.com/') + 'api/'

// Create a superagent instance with promise support
const superagent = superagentPromise(_superagent, window.Promise);

// Function to handle errors returned from API requests
const handleErrors = err => {
    if (err && err.response && err.response.status === 401) {
        // If the error is due to unauthorized access (status 401),
        // log out the user and redirect to the login page
        stores.userStore.logout().then(() => {
            return <Navigate to="/login" />;
        });
        console.error('BACKEND', err.message);
    }
    return err;
};

// Function to extract response body from API responses
const responseBody = res => res.body;

// Plugin to set authorization token in requests
const tokenPlugin = req => {
    if (stores.userStore.token) {
        req.set('authorization', `Token ${stores.userStore.token}`);
    }
};

// Object containing common request methods for different HTTP verbs
const requests = {
    del: url => superagent.del(`${API_ROOT}${url}`).use(tokenPlugin).end(handleErrors).then(responseBody),
    get: url => superagent.get(`${API_ROOT}${url}`).use(tokenPlugin).end(handleErrors).then(responseBody),
    patch: (url, body) => superagent.patch(`${API_ROOT}${url}`, body).use(tokenPlugin).end(handleErrors).then(responseBody),
    post: (url, body) => superagent.post(`${API_ROOT}${url}`, body).use(tokenPlugin).end(handleErrors).then(responseBody),
};

// Object containing authentication-related API requests
const Auth = {
    // Function to login a user
    login: body => requests.post('users/actions/login', body),
    // Function to register a user
    register: body => requests.post('entities/actions/register', body),
    // Function to request a password reset
    passwordReset: body => requests.post('users/actions/request-password-reset', body),
    // Function to select the entity
    selectEntity: body => requests.post('users/actions/entity-selection/', body),
};

// Object containing user-related API requests
const User = {
    // Function to get the current user
    current: type => requests.get(type === 2 ? 'professionals/me/' : 'managers/me/'),
    // Function to get a user by unique ID
    byUniqueId: ({ number, country, email }) =>
        requests.get(`public/persons?unique_id_country=${encode(country)}&unique_id_number=${encode(number)}&email=${encode(email)}`),
    // Function to save user data
    save: (body) => requests.patch('professionals/me/', body),
};

// Object containing entity-related API requests
const Entity = {
    // Function to get a user's entities, given their username
    byUsername: username =>
        requests.get(`public/entities/?username=${encode(username)}`),
    // Function to get an entity by ID
    get: id => requests.get(`public/entities/?entity_id=${encode(id)}`),
    // Function to update the entity data
    update: body => requests.patch(`entities/edit`, body),
};

// Object containing professional-related API requests
const Professional = {
    // Function to get a list of professionals
    list: () => requests.get(`professionals/`),
    // Function to get a specific professional by ID
    get: id => requests.get(`professionals/${encode(id)}/`),
    // Function to add a new professional
    add: body => requests.post(`professionals/actions/register`, body),
    // Function to set the status of a professional's account
    setStatus: (id, body) => requests.post(`professionals/${encode(id)}/actions/set-account-status/`, body),
    // Function to remove a professional from the current entity
    removeFromEntity: body => requests.post(`professionals/actions/remove-from-entity/`, body),
    // Function to get a list of groups
    getGroups: () => requests.get(`professionals/groups/view`),
    // Function to create a new group
    addGroup: body => requests.post(`professionals/groups`, body),
};

const MeasurementProfiles = {
    // Function to get a list of measurement profiles
    list: body => requests.post(`evaluation-profiles/actions/sync`, body),
    // Function to add a new measurement profile
    add: body => requests.post(`evaluation-profiles/actions/sync`, body),
    // Function to update a measurement profile
    update: body => requests.post(`evaluation-profiles/actions/sync`, body),
    // Function to delete a measurement profile
    delete: body => requests.post(`evaluation-profiles/actions/sync`, body),
};

// Object containing client-related API requests
const Client = {
    // Function to get a list of clients
    list: () => {
        // Get a list of clients and return the response
        return requests.get(`patients/`).then(response => {
            return response;
        });
    },
    // Function to get a specific client by ID
    get: id => requests.get(`patients/${encode(id)}/`),
    // Function to get the body weight evolution of a client
    getBodyWeightEvo: id => requests.get(`entity-patients/${encode(id)}/body-weight-evolution/`),
    getBMIEvolution: id => requests.get(`entity-patients/${encode(id)}/bmi-evolution/`),
    getMuscleMassEvolution: id => requests.get(`entity-patients/${encode(id)}/muscle-mass-evolution/`),
    getBodyFatEvolution: id => requests.get(`entity-patients/${encode(id)}/body-fat-evolution/`),
    // Function to get the anamnesis of a client
    getAnamnesis: id => requests.get(`anamnesis/${encode(id)}/`),

    // Function to add a new client
    add: body => requests.post(`entity-patients/actions/sync`, body),

};
// Object containing appointment-related API requests
const Appointment = {
    // Function to get a list of appointments
    list: (id, page, pageSize) => requests.get(`appointments/?patientId=${encode(id)}&page=${page}&page_size=${pageSize}`),
    // Function to get a specific appointment by ID
    get: id => requests.get(`appointments/${encode(id)}/`),
    // Function to add a new appointment
    add: body => requests.post(`appointments/actions/sync`, body),
    // Function to export an appointment report
    export: body => requests.post(`appointments/actions/export`, body),
    // Function to generate a Google Meet link for an appointment
    generateMeetingLink: () => requests.post(`appointments/actions/generate-meeting-link`),
};

const Calendar = {
    get: (start, end) => requests.get(`appointment-calendar?start=${encode(start)}&end=${encode(end)}`),
    add: body => requests.post(`appointment-calendar`, body),
    update: body => requests.patch(`appointment-calendar`, body),
}

const Anamnesis = {
    get: id => requests.get(`anamnesis/${encode(id)}/`),
    update: body => requests.post(`anamnesis/actions/sync`, body),
};

const Devices = {
    list: () => requests.get('devices'),
    add: body => requests.post('devices', body),
    update: body => requests.patch('devices', body),
};

export default {
    Auth,
    User,
    Entity,
    Professional,
    Client,
    MeasurementProfiles,
    Appointment,
    Anamnesis,
    Calendar,
    Devices,
};


