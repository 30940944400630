import * as React from 'react';
import { getImage } from '../constants';

const LazyImage = ({ src, refProp, alt, style, ...props }) => {
  const [imageSrc, setImageSrc] = React.useState(null);

  React.useEffect(() => {
    const loadImage = async () => {
      const resolvedSrc = await getImage(src);
      setImageSrc(resolvedSrc);
    };

    loadImage();
  }, [src]); // Re-run the effect when `src` changes

  // Optionally, add a fallback or loading state
  if (!imageSrc) {
    return <div>Loading...</div>; // Or a placeholder image
  }

  return <img src={imageSrc} ref={refProp} alt={alt} style={style} {...props} />;
};

export default LazyImage;
