import React, { useEffect, useState } from 'react';
import LanguagePopover from './LanguagePopover.jsx';
import { Routes, Route, Navigate, useParams, useLocation } from 'react-router-dom';
import { stores } from '../stores/index.js';
import { observer } from 'mobx-react-lite';
import Sidebar from './Sidebar.jsx';
import { Alert, Container } from '@mui/material';
import { UserPopover } from './UserPopover.jsx';
import { translate } from '../translations/translate.js';

/*
    THIS WILL INCLUDE THE LOGO, PROFILE AND SIDEBAR 
    Include the components that'll be rendered in every page.
*/
const Layout = observer(({ routes }) => {
    const [toastMessage, setToastMessage] = useState(null);
    const [toastSeverity, setToastSeverity] = useState(null);
    const location = useLocation();

    const showToast = (message, severity = 'info') => {
        setToastMessage(message);
        setToastSeverity(severity);

        setTimeout(() => {
            setToastMessage(null);
            setToastSeverity(null)
        }, 5000);
    }

    useEffect(() => {
        if(!location?.search) return;

        const queryParams = new URLSearchParams(location.search);
        if (queryParams.get('switchEntitySuccess') === 'true') {
            showToast(translate('entity_switched_successfully'), 'success');

            // Clean up the URL (remove the query parameter)
            if (window.history.replaceState) {
                const cleanUrl = window.location.pathname + window.location.hash;
                window.history.replaceState({}, document.title, cleanUrl);
            }
        }
    }, [location?.search]);

    return (
        <Container sx={{ width: '100vw', height: '100%', display: 'flex', flexDirection: 'row', maxWidth: '100vw !important' }}>
            {!stores.userStore.loggedIn && <Navigate to='/login' />}

            <Sidebar />

            <Container
                sx={{
                    padding: '70px 0px 40px 0px',
                    '@media (max-width: 1199px)': {
                        paddingLeft: '100px',
                        overflowY: 'auto',
                    },
                }}
            >
                <Routes>
                    {routes.map((route, index) => (
                        <Route
                            key={index}
                            path={route.path}
                            element={<route.component routes={route.routes} />}
                        />
                    ))}
                </Routes>
            </Container>
            <Container
                sx={{
                    position: 'fixed',
                    width: 'fit-content',
                    right: 0,
                    top: 0,
                    padding: '10px 0px',
                    zIndex: 1000,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 'calc(10px + 1vw)',
                }}
            >
                <LanguagePopover />
                <UserPopover />
            </Container>
            {
                toastMessage && (
                    <Alert
                        severity={toastSeverity}
                        sx={{
                            position: 'fixed',
                            top: 20,
                            left: '50%',
                            transform: 'translate(-50%, 0)',
                            zIndex: 1000
                        }}
                    >
                        {toastMessage}
                    </Alert>
                )
            }
        </Container>
    )
});

export default Layout;