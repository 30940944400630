import { action, makeObservable, observable, runInAction } from "mobx";
import agent from "../agent";
import { stores } from "./";
import { formatDateWithMicroseconds } from "../utils";

class AnamnesisStore {
  anamnesisData = null;
  inProgress = false;
  errors = undefined;

  constructor() {
    makeObservable(this, {
      anamnesisData: observable,
      inProgress: observable,
      errors: observable,
      loadAnamnesis: action,
      updateAnamnesis: action,
      clear: action,
      setAnamnesisField: action,
      reset: action,
    });
  }

  loadAnamnesis = async () => {
    this.inProgress = true;
    this.errors = undefined;

    try {
      const response = await agent.Anamnesis.get(stores.clientStore.selectedClient?.anamnesis_id);
      runInAction(() => {
        this.anamnesisData = response;
      });
    } catch (error) {
      runInAction(() => {
        this.errors = error;
        console.error("Error loading anamnesis:", error);
      });
    } finally {
      runInAction(() => {
        this.inProgress = false;
      });
    }
  };

  updateAnamnesis = async (updatedData) => {
    this.inProgress = true;
    this.errors = undefined;

    const body = {
      updated: [updatedData],
      deleted: [],
      last_sync: "",
    };

    try {
      updatedData.updated_at = formatDateWithMicroseconds();

      if (!updatedData.association_date) {
        updatedData.association_date = new Date().toISOString();
      }
      const response = await agent.Anamnesis.update(body);
      runInAction(() => {
        if (response.updated && response.updated.length > 0) {
          console.log("response.updated", response.num_updated !== 0);
          this.anamnesisData = response.updated[response.updated.length - 1];
        }
      });
    } catch (error) {
      runInAction(() => {
        this.errors = error;
        console.error("Error updating anamnesis:", error);
      });
    } finally {
      runInAction(() => {
        this.inProgress = false;
      });
    }
  };

  setAnamnesisField = (field, value) => {
    if (this.anamnesisData) {
      this.anamnesisData = {
        ...this.anamnesisData,
        [field]: value,
      };
    }
  };

  clear() {
    this.anamnesisData = null;
  }

  reset() {
    this.clear();
    this.inProgress = false;
    this.errors = undefined;
  }
}

export default AnamnesisStore;
