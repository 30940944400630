import dayjs from 'dayjs';

import format from 'format-number-with-string';

var moment = require('moment');

export function parseOdooDateTime(dt) {
    return dt ? moment(dt).toDate() : null; //FIXME: Check that we can call toDate() here if dt is invalid
}

export function parsePickerDate(dt) {
    return dt ? moment(dt, 'DD/MM/YYYY').toDate() : null; //FIXME: Check that we can call toDate() here if dt is invalid
}

export function formatDateToPrint(dt, format = 'DD/MM/YYYY') {
    return dt ? moment(new Date(dt)).format(format) : dt;
}

export function ageFromBirthday(birthday, referenceDate = new moment()) {
    if (!birthday) return null;

    return moment(referenceDate).diff(birthday, 'years');
}

export function currentDate() {
    let d = new Date();
    d.setHours(0, 0, 0, 0);
    return d;
}

export function minAndMaxDatetimeInYear(year) {
    return [new Date(year, 0, 0, 0, 0, 0, 0), new Date(year, 11, 31, 23, 59, 59, 999)];
}

(function () {
    let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    let months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEPT', 'OCT', 'NOV', 'DEC'];

    Date.prototype.getMonthName = function () {
        return months[this.getMonth()];
    };
    Date.prototype.getDayName = function () {
        return days[this.getDay()];
    };
})();

export function formatAMPM(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    // hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    return [hours, minutes, ampm];
}

export function addMonthsToDate(date, months) {
    let newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() + months);
    return newDate;
}

export function daysBetweenDates(dateA, dateB) {
    const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
    const timeA = new Date(dateA).setHours(0, 0, 0, 0);
    const timeB = new Date(dateB).setHours(0, 0, 0, 0);
    const differenceInTime = Math.abs(timeA - timeB);
    return Math.round(differenceInTime / oneDay);
}

export function msToTime(s) {
    const tenMilliSeconds = parseInt(s / 10, 10);

    const seconds = parseInt(tenMilliSeconds / 100, 10);
    const minutes = parseInt(seconds / 60, 10);

    return `${minutes} : ${format(seconds % 60, '00')} . ${format(tenMilliSeconds % 100, '00')}`;
}

export function toUTCZulu(timestamp, withZ = true) {
    let format = 'YYYY-MM-DDTHH:mm:ss.SSS';
    if (withZ) format += 'Z';

    print_console('Date: ', new Date(timestamp));

    return moment(new Date(timestamp)).format(format);
}


export const formatDateWithMicroseconds = () => {
    const now = new Date();
    now.setHours(now.getHours()); 
    return now.toISOString();
  };

export  const countryToFlag = (isoCode) =>
    typeof String.fromCodePoint !== "undefined"
      ? isoCode.toUpperCase().replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
      : isoCode;

export const formatBackendDate = (dateString) => {
    return dateString ? dayjs(dateString).format("YYYY-MM-DD") : '';
  };

export const countryMapping = {
    UK: "GB", 
  };
  
export const mapCountryCode = (code) => {
    return countryMapping[code] || code;
  };

  export function reorderFolds(folds, foldsOrder) {
    const targetOrder = Object.assign({}, ...foldsOrder.map((foldId, index) => ({ [foldId]: index })));
    return folds.sort((a, b) => targetOrder[a.id || a] - targetOrder[b.id || b]);
}

export function shallowDictCopy(o) {
  return Object.assign({}, o);
}

const ENABLE_PRINTS = true;

export const print_console = ENABLE_PRINTS ? console.log : () => null;

export function objectListToMap(objectList, mapKey) {
  let map = {};
  for (let obj of objectList) {
      map[obj[mapKey]] = obj;
  }

  return map;
}