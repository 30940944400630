import { makeAutoObservable } from 'mobx';
import { stores } from '.';

const STATUS = {
    DISCONNECTED: 'disconnected',
    SCANNING: 'scanning',
    CONNECTING: 'connecting',
    CONNECTED: 'connected',
};

export const DEVICE_STATE = {
    READY_TO_MEASURE: 112,
    MEASURING: 144,
    MEASUREMENT_FINISHED: 160,
    DOUBLE_TAP: 96,
    DEFAULT: 0,
};

const SERVICES = {
    LIPOWISE: '5c44d4c0-9ead-4a84-a936-30147b46ef9c',
    BATTERY: '0000180f-0000-1000-8000-00805f9b34fb',
};

const CHARACTERISTICS = {
    LIPOWISE_RECEIVE: '5c44d4c1-9ead-4a84-a936-30147b46ef9c',
    LIPOWISE_SEND: '5c44d4d1-9ead-4a84-a936-30147b46ef9c',
    BATTERY: '00002a19-0000-1000-8000-00805f9b34fb',
};

const CODES = {
    PROTOCOL_TIME: 65,
    INACTIVITY_TIME: 66,
};

const Int16 = (value) => {
    let ref = value & 0xffff;
    return ref > 0x7fff ? ref - 0x10000 : ref;
};

const calibrateLipowiseValue = (value) => {
    return value > 0 && value < 2000
        ? 0.07794 * value -
        0.438 * Math.sin((Math.PI * value) / 798.6) -
        0.03944 * Math.sin((Math.PI * value) / 379.9)
        : 0.0;
};

const calibrateGripwiseValue = value => {
    return 0.030603 * value + 0.0089251
}

class BluetoothStore {
    errors = null;
    status = STATUS.DISCONNECTED;
    connectedDevice = null;
    measurementInProgress = false;
    batteryLevel = null;
    deviceValue = null;
    deviceState = null;
    lastDeviceState = null;
    deviceTimer = null;
    trialValues = [];
    protocolTime = null;
    inactivityTime = null;
    calibrationFunction = null;

    constructor() {
        makeAutoObservable(this);
    }

    setErrors(errors) {
        this.errors = errors;
    }

    setStatus(status) {
        this.status = status;
    }

    setConnectedDevice(device) {
        this.connectedDevice = device;
    }

    setMeasurementInProgress(inProgress) {
        this.measurementInProgress = inProgress;
    }

    setBatteryLevel(level) {
        this.batteryLevel = level;
    }

    setDeviceValue(value) {
        this.deviceValue = value;
    }

    setDeviceState(state) {
        this.deviceState = state;
    }

    setLastDeviceState(state) {
        this.lastDeviceState = state;
    }

    setDeviceTimer(timer) {
        this.deviceTimer = timer;
    }

    setTrialValues(values) {
        this.trialValues = values;
    }

    setProtocolTime(time) {
        this.protocolTime = time;
    }

    setInactivityTime(time) {
        this.inactivityTime = time;
    }

    async scan(deviceName = '') {
        let result = false;

        this.setStatus(STATUS.SCANNING);
        this.setErrors(null);

        const filters = deviceName.length > 0
            ? [{ namePrefix: deviceName }]
            : [{ namePrefix: 'Lipowise' }, { namePrefix: 'Gripwise' }];

        try {
            const device = await navigator.bluetooth.requestDevice({
                filters,
                optionalServices: [SERVICES.LIPOWISE, SERVICES.BATTERY],
            });

            if (device.name) result = await this.connect(device);
        } catch (error) {
            console.error('Scan Error:', error);
            this.setErrors(error.message || 'Scan failed');
            result = false;
        }

        return result;
    }

    async connect(device) {
        console.log('Connecting to:', device)
        let result = false;

        this.setStatus(STATUS.CONNECTING);
        this.setErrors(null);

        try {
            const server = await device.gatt.connect();
            console.log('Connected to GATT server:', server);

            this.setConnectedDevice(device);
            this.setStatus(STATUS.CONNECTED);

            const batteryService = await server.getPrimaryService(SERVICES.BATTERY);
            const batteryCharacteristic = await batteryService.getCharacteristic(CHARACTERISTICS.BATTERY);

            const readBatteryLevel = async () => {
                try {
                    const value = await batteryCharacteristic.readValue();
                    this.setBatteryLevel(value.getUint8(0));
                } catch (error) {
                    console.error('Battery Error:', error);
                    this.setErrors(error.message || 'Battery read failed');
                }
            };

            await readBatteryLevel();
            const batteryInterval = setInterval(readBatteryLevel, 3000);

            await this.setupDeviceNotifications(device);

            device.addEventListener('gattserverdisconnected', () => {
                console.log('Device disconnected');
                this.setConnectedDevice(null);
                this.setStatus(STATUS.DISCONNECTED);
                clearInterval(batteryInterval);
            });

            let deviceInList = null;
            if (window.api && stores.userStore.currentUser) {
                const userDevices = await window.api.getDevices(stores.userStore.currentUser.username);

                if (userDevices) {
                    const matchingDevices = userDevices.filter(d => d.macAddress === this.parseMacAddressFromName(device.name));
                    deviceInList = matchingDevices.length > 0 ? matchingDevices[matchingDevices.length - 1] : null;

                    if (deviceInList) {
                        await this.writeProtocolTime(device, deviceInList.protocolTime);
                        await this.writeInactivityTime(device, deviceInList.inactivityTime);
                    }
                }
            }

            if (!deviceInList) {
                await this.writeProtocolTime(device, 300);
                await this.writeInactivityTime(device, 12000);
            }

            this.calibrationFunction = device?.name?.startsWith('Lipowise') ? calibrateLipowiseValue : calibrateGripwiseValue;

            result = true;
        } catch (error) {
            console.error('Connection Error:', error);
            this.setErrors(error.message || 'Connection failed');
            this.setStatus(STATUS.DISCONNECTED);
            result = false;
        }

        return result;
    }

    async disconnect() {
        console.log('Disconnecting...');
        if (this.connectedDevice) {
            console.log('Disconnecting from:', this.connectedDevice.name);

            this.connectedDevice.removeEventListener('gattserverdisconnected', () => {
                console.log('Device disconnected');
                this.setConnectedDevice(null);
                this.setStatus(STATUS.DISCONNECTED);
            });

            try {
                if (this.connectedDevice.gatt.connected) {
                    await this.connectedDevice.gatt.disconnect();
                }
            } catch (error) {
                console.warn('Error during disconnect:', error);
            }

            this.setConnectedDevice(null);
            this.setDeviceValue(null);
            this.setTrialValues([]);
            this.setStatus(STATUS.DISCONNECTED);
        }
        this.setStatus(STATUS.DISCONNECTED);
    }

    async autoConnect(deviceName) {
        this.setStatus(STATUS.SCANNING);
        this.setErrors(null);

        try {
            console.log(`Requesting to auto-connect to device: ${deviceName}`);

            if (!window.api) throw new Error('API not available');
            if (!deviceName) throw new Error('Device name not provided');

            console.log('Starting the auto-connect process');
            let startAutoConnectSuccess = await window.api.autoConnect(deviceName);

            if (!startAutoConnectSuccess) throw new Error('Auto-connect failed');

            if (!(await this.scan(deviceName))) throw new Error('Auto-connect failed');

            return true;
        } catch (error) {
            console.error('Auto-connect error:', error);
            this.setErrors(error.message || 'Auto-connect failed');
            return false;
        }
    }

    handleCharacteristicValueChanged(event, deviceName) {
        /* 
        **READING DATA FROM A DEVICE'S CHARACTERISTIC**
        
        The value read from each characteristic is in 8 bytes.
        Here, we're handling the main characteristic for each device (WISIFY_RECEIVE).
        Only the 4 rightmost bytes are important for us, and the information to retrieve from them is represented below.

        +----------+----------+----------+----------+
        | AAAAAAAA | AAAAAAAA | BBBBCCCC | CCCCCCCC |
        +----------+----------+----------+----------+
        |  Byte 3  |  Byte 2  |  Byte 1  |  Byte 0  |
        +----------+----------+----------+----------+

        A -> Characteristic value - to be calibrated by each device
        B -> State
        C -> Timer
        */

        // 8 bytes of data from the characteristic
        const byteArray = new Int8Array(event.target.value.buffer);

        // A - characteristic value
        let calibrationFunction = deviceName?.startsWith('Lipowise') ? calibrateLipowiseValue : calibrateGripwiseValue;
        let value = calibrationFunction(Math.abs(Int16(((byteArray[3] & 0xff) << 8) | (byteArray[2] & 0xff))));

        if (this.deviceValue !== value) {
            this.setDeviceValue(value);
        }

        // B - state
        const state = byteArray[1] & 0xf0

        // C - timer 
        const timer = ((byteArray[1] & 0x0f) << 8) | (byteArray[0] & 0xff);

        /* 
        **DEVICE STATE MANAGEMENT**
 
        State space:
        - DEFAULT_STATE [1]
        - READY_TO_MEASURE [2]
        - MEASURING [3]
        - MEASUREMENT_HAS_FINISHED [4]
        - DOUBLE_TAP [5]
 
        Valid transitions:
        - *\{2} -> 2
        - {1,2} -> 3
        - 3 -> 3
        - 3 -> 4
        - *\{5} -> 5
        (any other transition won't be considered)
        */

        const prevLastDeviceState = this.lastDeviceState;
        switch (state) {
            // *\{2} -> 2
            case DEVICE_STATE.READY_TO_MEASURE:
                if (prevLastDeviceState !== DEVICE_STATE.READY_TO_MEASURE) break;
                this.setMeasurementInProgress(true);
                this.setTrialValues([]);
                break;


            case DEVICE_STATE.MEASURING:
                switch (prevLastDeviceState) {
                    // {1,2} -> 3
                    case DEVICE_STATE.DEFAULT:
                    case DEVICE_STATE.READY_TO_MEASURE:
                        this.setTrialValues([])
                        break;

                    // 3 -> 3
                    case DEVICE_STATE.MEASURING:
                        this.setTrialValues([...this.trialValues, value]);
                        this.setDeviceValue(value);
                        break;

                    default:
                        break
                }
                break;

            // 3 -> 4
            case DEVICE_STATE.MEASUREMENT_FINISHED:
                if (prevLastDeviceState === DEVICE_STATE.MEASURING) {
                    this.setMeasurementInProgress(false);
                    this.setTrialValues([...this.trialValues, value]);
                }
                break;

            // *\{5} -> 5
            case DEVICE_STATE.DOUBLE_TAP:
                // TODO: Implement double tap handling
                break;

            default:
                break;
        }

        this.setDeviceState(state);
        this.setLastDeviceState(state);
        this.setDeviceTimer(timer);
    }

    async setupDeviceNotifications(device) {
        const service = await device.gatt.getPrimaryService(SERVICES.LIPOWISE);
        const receiveCharacteristic = await service.getCharacteristic(CHARACTERISTICS.LIPOWISE_RECEIVE);

        await receiveCharacteristic.startNotifications();

        receiveCharacteristic.removeEventListener('characteristicvaluechanged', event => this.handleCharacteristicValueChanged(event, device.name));
        receiveCharacteristic.addEventListener('characteristicvaluechanged', event => this.handleCharacteristicValueChanged(event, device.name));
    }

    parseMacAddressFromName(name) {
        if (!name) return '';

        const parts = name.split('_');

        if (parts.length !== 2) return null;

        const rawMac = parts[1];

        return rawMac.match(/.{1,2}/g).join(':').toUpperCase();
    }

    async writeProtocolTime(connectedDevice, protocolTime) {
        console.log('Writing protocol time', protocolTime);
        try {
            const server = await connectedDevice.gatt.connect();
            const service = await server.getPrimaryService(SERVICES.LIPOWISE);
            const characteristic = await service.getCharacteristic(CHARACTERISTICS.LIPOWISE_SEND);

            const protocolTimeValueWithCode = (CODES.PROTOCOL_TIME << 24) | protocolTime;
            const protocolTimeBuffer = new ArrayBuffer(4);
            const protocolTimeDataView = new DataView(protocolTimeBuffer);
            protocolTimeDataView.setInt32(0, protocolTimeValueWithCode, true);

            await characteristic.writeValue(new Uint8Array(protocolTimeBuffer));

            console.log('Successfully wrote protocol time');

            this.setProtocolTime(protocolTime);
            return true;
        } catch (error) {
            console.error('Protocol Time Error:', error);
            this.setErrors(error.message || 'Protocol Time write failed');
            return false;
        }
    }

    async writeInactivityTime(connectedDevice, inactivityTime) {
        try {
            const server = await connectedDevice.gatt.connect();
            const service = await server.getPrimaryService(SERVICES.LIPOWISE);
            const characteristic = await service.getCharacteristic(CHARACTERISTICS.LIPOWISE_SEND);

            const inactivityTimeValueWithCode = (CODES.INACTIVITY_TIME << 24) | inactivityTime;
            const inactivityTimeBuffer = new ArrayBuffer(4);
            const inactivityTimeDataView = new DataView(inactivityTimeBuffer);
            inactivityTimeDataView.setInt32(0, inactivityTimeValueWithCode, true);

            await characteristic.writeValue(new Uint8Array(inactivityTimeBuffer));

            console.log('Successfully wrote inactivity time');

            this.setInactivityTime(inactivityTime);
            return true;
        } catch (error) {
            console.error('Inactivity Time Error:', error);
            this.setErrors(error.message || 'Inactivity Time write failed');
            return false;
        }
    }

    getDeviceImage(deviceName) {
        let image = null;

        const characterToTest = deviceName[deviceName.indexOf('_') - 1];

        switch (characterToTest) {
            case 'P':
                image = 'lipowise_pro.png';
                break;
            case 'L':
                image = 'lipowise_light.png';
                break;
            default:
                image = 'lipowise_light.png';
                break;
        }

        if (deviceName.includes('Gripwise')) {
            image = 'gripwise.png';
        }

        return image;
    }

    resetBluetooth() {
        this.setErrors(null);
        this.setStatus(STATUS.DISCONNECTED);
        this.setConnectedDevice(null);
        this.setMeasurementInProgress(false);
        this.setBatteryLevel(null);
        this.setDeviceValue(null);
        this.setDeviceState(null);
        this.setLastDeviceState(null);
        this.setDeviceTimer(null);
        this.setTrialValues([]);
    }

    reset() {
        this.resetBluetooth();
        this.setProtocolTime(null);
        this.setInactivityTime(null);
    }
}

export default BluetoothStore;