import Step from '../Step';

class AnthropometryStep extends Step {
    anthropometryFields = []
    trials = {}

    constructor(name, preset) {
        super(name, preset);
    }

    initFromData(data) {
        this.anthropometryFields = data.anthropometryFields || data.anthropometry_fields;
    }

    saveFields(anthropometryFields){
        this.anthropometryFields = anthropometryFields;
    }
}

export default AnthropometryStep;
