import Step from '../Step';

class BasicMeasurementsStep extends Step {
    constructor(name) {
        super(name);
    }

    basicMeasurements = [];

    trials = {};

    // fields used for bodyMassPrevious
    previousWeightAppointmentId = null;
    previousCustomWeight = null;
    acceptedWeightLoss = null;

    // we're saving the settings as a list of strings, where the elements are the selected settings
    // anything that is not in the list is considered not selected, thus false
    initFromData(data) {
        this.basicMeasurements = data.basic_measurements || data.basicMeasurements;
    }

    // we're saving the settings as a list of strings, where the elements are the selected settings, which are the ones set to true
    saveBasicMeasurements(basicMeasurements) {
        this.basicMeasurements = Object.keys(basicMeasurements).filter(key => basicMeasurements[key]);
    }
    
    toJSON() {
        return {
            name: this.name,
            basic_measurements: this.basicMeasurements,
        };
    }
}

export default BasicMeasurementsStep;