import Step from '../Step';

class BioImpedanceStep extends Step {
    constructor(name, preset) {
        super(name, preset);
    }

    bodyFat = null;
    muscleMass = null;
    boneMass = null;
    residualMass = null;
    leanMass = null;
    visceralFat = null;

    // we're saving the settings as a list of strings, where the elements are the selected settings
    // anything that is not in the list is considered not selected, thus false
    initFromData(data) {
        this.bodyFat = data.bodyFat || data.body_fat;
        this.muscleMass = data.muscleMass || data.muscle_mass;
        this.boneMass = data.boneMass || data.bone_mass;
        this.residualMass = data.residualMass || data.residual_mass;
        this.leanMass = data.leanMass || data.lean_mass;
        this.visceralFat = data.visceralFat || data.visceral_fat;
    }

    toJSON() {
        return {
            name: this.name,
            body_fat: this.bodyFat,
            muscle_mass: this.muscleMass,
            bone_mass: this.boneMass,
            residual_mass: this.residualMass,
            lean_mass: this.leanMass,
            visceral_fat: this.visceralFat,
        };
    }
}
export default BioImpedanceStep;
